body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section {
    width: 100%;
    height: 100vh;
    display: flex;
    transition: opacity 1s ease-out;
}

#welcome-page {
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    color: rgb(40, 66, 90);
    text-shadow: 4px 4px 8px rgb(255, 255, 255); /* Black shadow for contrast */
}

 /*Additional styles for headings or specific text elements */
#welcome-page p {
    color: #28425a;
    position: absolute;
    bottom: 0;
    font-size: 2vw; /* Starting point, adjust based on your design */
    margin-bottom: 10px;
    text-shadow: 3px 3px 6px rgb(255, 255, 255);
}

#welcome-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("background/background_spray.webp");
    background-repeat: round;
    background-size: clamp(20vh, 50vh, 50vh);
    opacity: 0.3; /* Set the desired transparency level here */
    z-index: -1;
}

#about-page {
    color: #ffffff;
    justify-content: center; /* Horizontally centers content in the container */
    align-items: center; /* Vertically centers content in the container */
    position: relative;
    text-align: center;
    font-size: 2vw; /* Starting point, adjust based on your design */
    background: #28425a;
    background-blend-mode: color;
}

#about-page p {
    color: #ffffff;
    position: absolute;
    bottom: 0;
    font-size: 2vw; /* Starting point, adjust based on your design */
    margin-bottom: 10px;
}

#content {
    font-size: 3vh;
    transition: opacity 1s ease;
    position: relative;
    overflow: hidden;
}

#content h1 {
    max-height: 10vh;
    position: absolute;
    font-size: 2vh;
}

.side-menu {
    width: 0;
    height: 100%;
    position: absolute; /* Positioned relative to the container */
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #28425a;
    overflow-x: hidden;
    transition: width 0.7s;
    padding-top: 3vh;
}

.side-menu ul {
    list-style-type: none;
    padding: 0;
}

.side-menu ul li {
    padding: 1vh 1vh 1vh 4vh;
    text-decoration: none;
    font-size: 2vh;
    color: white;
    display: block;
}

.side-menu ul li:hover {
    color: #f1f1f1;
}

.main-content {
    margin-top: 5vh;
    font-size: 3vh;
    padding: 0 2vh;
    transition: margin-left 0.5s;
    position: absolute;
    height: 100%;
}

.menu-indicator {
    width: 3vh;
    height: 100%;
    background-color: #28425a;
    left: 0;
    top: 0;
    transition: background-color 0.3s;
    z-index: 2;
}

.menu-indicator:hover {
    background-color: #000000;
}

